























import { Observer } from 'mobx-vue'
import { Component, Provide, Vue, Watch } from 'vue-property-decorator'
import { PostedJobDetailViewmodel } from '@/modules/recruitment/daos/viewmodels/posted-job-detail-viewmodel'

@Observer
@Component({
  components: {
    imformation: () => import('@/modules/recruitment/daos/components/posted-job-detail/information.vue'),
    'posted-job-detail': () => import('@/modules/recruitment/daos/components/posted-job-detail/posted-job-detail.vue'),
    'posted-job-seen': () => import('@/modules/recruitment/daos/components/posted-job-detail/posted-job-seen-page.vue'),
  },
})
export default class extends Vue {
  @Provide() vm = new PostedJobDetailViewmodel()
  @Watch('$route.params.id', { immediate: true }) onPostedJobChange(id) {
    if (id) this.vm.fetchData(id)
  }

  destroyed() {
    this.vm.destroy()
  }
}
